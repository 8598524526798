<template>
  <footer class="footer">
    <p v-if="advertiserFooter" v-text="advertiserFooter" class="secondary-text mb-0" />

    <div class="footer__links">
      <Copyright />
      <SecureShopping />
    </div>
  </footer>
</template>

<script>
import { mapState } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import SecureShopping from '@/components/Footer/SecureShopping/index.vue';
import Copyright from '@/components/Footer/Copyright/index.vue';

export default {
  name: 'Footer',
  components: {
    SecureShopping,
    Copyright,
  },
  computed: {
    ...mapState(useAdvertiserStore, ['companyName', 'advertiserFooter']),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
