<template>
  <div class="loading-logo">
    <img tabindex="0" class="loading-logo--top" :src="logo" :alt="companyName + ' Logo'" />
    <span class="loading-logo--bottom" v-text="'Buy directly from ' + companyName"></span>
  </div>
</template>

<script>
// Stores
import { mapState } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';

export default {
  name: 'IconMerchant',
  computed: {
    ...mapState(useAdvertiserStore, ['logo', 'companyName']),
  },
};
</script>
