export class ShopthruError extends Error {
  sourceError;

  constructor(message: string, error?: unknown) {
    super(message);
    this.name = 'ShopthruError';
    this.sourceError = error;

    console.error('ShopthruError thrown - ', message, error);
  }
}

/**
 * PAYMENT
 */
export class PaymentError extends ShopthruError {
  constructor(message: string, error: unknown) {
    super(message, error);
    this.name = 'PaymentError';
  }
}

export class GooglePaymentError extends PaymentError {
  constructor(message: string, error: unknown) {
    super(message, error);
    this.name = 'GooglePaymentError';
  }
}

export class ApplePaymentError extends PaymentError {
  constructor(message: string, error: unknown) {
    super(message, error);
    this.name = 'ApplePaymentError';
  }
}

export class PayPalPaymentError extends PaymentError {
  constructor(message: string, error: unknown) {
    super(message, error);
    this.name = 'PayPalPaymentError';
  }
}

export class HostedFieldsPaymentError extends PaymentError {
  constructor(message: string, error: unknown) {
    super(message, error);
    this.name = 'HostedFieldsPaymentError';
  }
}

/**
 * SHIPPING
 */
export class ShippingError extends ShopthruError {
  constructor(message: string, error: unknown) {
    super(message, error);
    this.name = 'ShippingError';
  }
}

/**
 * STOCK LEVEL
 */
export class OutOfStockError extends ShopthruError {
  constructor() {
    super('The product is out of stock');
    this.name = 'OutOfStockError';
  }
}

/**
 * ERROR PAGE
 */
export class ErrorBoundaryPageInferredError extends ShopthruError {
  constructor(message: string) {
    super(message);
    this.name = 'ErrorBoundaryPageInferredError';
  }
}

export class ErrorBoundaryPageGenericError extends ShopthruError {
  constructor(message: string) {
    super(message);
    this.name = 'ErrorBoundaryPageGenericError';
  }
}

/**
 * PRODUCT VARIANTS
 */
export class ProductVariantsLookupError extends ShopthruError {
  constructor(message: string) {
    super(message);
    this.name = 'ProductVariantsLookupError';
  }
}

/**
 * Customer Record
 */
export class CustomerRecordError extends ShopthruError {
  constructor(message: string) {
    super(message);
    this.name = 'CustomerRecordError';
  }
}
