import { defineStore } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { usePublisherStore } from '@/stores/PublisherStore.js';
import { useMainStore } from '@/stores/MainStore.js';
import { getProduct, getProductPricingAndAvailability } from '@/api/product';
import {
  ProductPricingAndAvailablitySimple,
  ProductPricingAndAvailablityConfigurable,
  type ProductBase,
  type ProductPricingAndAvailablitySimpleProps,
  type ProductPricingAndAvailablityConfigurableProps,
} from '@/types/product.types';

import { ProductVariantsLookupError } from '@/types/errors.types';
import { getOptionedProductHash } from '@/helpers/productVariants';
import { debounce } from '@/helpers/utils';

let temp = 0;

type DiscountLookupState =
  | null
  | 'LOOKUP_IN_PROGRESS'
  | 'LOOKUP_ERRED'
  | 'DISCOUNT_APPLIED'
  | 'DISCOUNT_NOT_APPLICABLE'
  | 'DISCOUNT_REVOKED';

type State = {
  product: ProductBase | null;
  productPricingAndAvailability:
    | ProductPricingAndAvailablitySimple
    | ProductPricingAndAvailablityConfigurable
    | null;
  productDescriptionOpen: boolean;
  productGalleryOpen: boolean;
  discountLookupState: DiscountLookupState;
  isShowStickyProductSummary: boolean;
  products: []; // TODO
  productVariants: []; // TODO
  isProductHasVariants: boolean;
  variantsMatrix: Record<string, ToDo>;
  // defaultSelectedOptions: {},
  isProductsInitialised: boolean;
};

const getInitialState = (): State => ({
  product: null,
  productPricingAndAvailability: null,
  productDescriptionOpen: false,
  productGalleryOpen: false,
  discountLookupState: null,
  isShowStickyProductSummary: false,
  products: [],
  productVariants: [],
  isProductHasVariants: false,
  variantsMatrix: {},
  // defaultSelectedOptions: {},
  isProductsInitialised: false,
});

export const useProductStore = defineStore('productStore', {
  state: (): State => {
    return getInitialState();
  },
  actions: {
    async fetchProductData(productCode: string) {
      const advertiserStore = useAdvertiserStore();
      const publisherStore = usePublisherStore();
      const mainStore = useMainStore();
      const result = await getProduct(productCode);

      const { link, product, retailer, publisher } = result.data.data;

      mainStore.fallbackUrl = link.fallback_url;

      this.product = {
        id: product.id,
        name: product.name,
        description: product.description,
        images: product.images || [],
        displayAttributes: product.display_attributes,
        sku: product.sku,
      } as ProductBase;

      this.isProductHasVariants = !!product.variants.products?.length;

      if (this.isProductHasVariants) {
        this.products = product.variants.products; // TODO not sure about the naming...
        this.productVariants = product.variants.options; // TODO not sure about the naming...
      }

      this.initProductOptions();

      advertiserStore.setData(retailer);
      publisherStore.setData(publisher);
    },

    async fetchProductPricingAndAvailability(productCode: string) {
      const result: {
        data: {
          product:
            | ProductPricingAndAvailablitySimpleProps
            | ProductPricingAndAvailablityConfigurableProps;
        };
      } = await getProductPricingAndAvailability(productCode);

      const { product: pricingAndAvailability } = result.data;

      if (this.isProductHasVariants) {
        const prAndAvail = pricingAndAvailability as ProductPricingAndAvailablityConfigurableProps;

        this.productPricingAndAvailability = new ProductPricingAndAvailablityConfigurable(
          prAndAvail,
        );
      } else {
        const prAndAvail = pricingAndAvailability as ProductPricingAndAvailablitySimpleProps;

        this.productPricingAndAvailability = new ProductPricingAndAvailablitySimple(prAndAvail);
      }

      this.isProductsInitialised = true;
    },

    initProductOptions() {
      if (this.isProductHasVariants) {
        if (!this.products?.length || !this.productVariants?.length) {
          throw new Error(
            'Product was specified as having variants but incomplete variant data was supplied!',
          );
        }

        this.variantsMatrix = this.products.reduce((acc, cur) => {
          const key = getOptionedProductHash(
            cur.variantOptions.map(({ variantCode, variantId }) => ({
              variantCode,
              val: variantId,
            })),
          );

          acc[key] = cur;
          return acc;
        }, {});

        /*
        const availableProductVariants = Object.keys(this.variantsMatrix);

        if (availableProductVariants.length) {
          // Just set the first one as default
          const defaultProductOptions = this.getOptionsFromProductHash(availableProductVariants[0]);

          defaultProductOptions.forEach((defaultProductOption) => {
            this.defaultSelectedOptions[defaultProductOption.variantCode] =
              defaultProductOption.variantId;
          });
        }
        */
      }
    },

    /*
    setInitialProductOptions() {
      const productVariations = this.productVariations;

      Object.keys(productVariations).forEach((key) => {
        let option = this.productVariations[key];

        // Set Option
        this.productOptions.options[option.id] = null;

        // Only set if option is required, else leave as false
        if (option.required) {
          this.productOptions.options[option.id] = option.values[0];
        }
      });
    },
    */

    toggleGalleryOverlay() {
      debounce(() => (this.productGalleryOpen = !this.productGalleryOpen), 100);
    },

    setDiscountLookupState(discountState: DiscountLookupState) {
      this.discountLookupState = discountState;
    },
  },
  getters: {
    getProductUiOptions: ({ productVariants }) => {
      return productVariants.map((productVariant) => ({
        optionCode: productVariant.variantCode,
        label: productVariant.label,
        values: productVariant.values.map((val) => ({
          id: val.variantId,
          label: val.label,
        })),
      }));
    },

    getProductVariantFromOptions: (state) => {
      return (productOptions: Record<string, string>) => {
        let productVariant;

        const productHash = getOptionedProductHash(
          Object.entries(productOptions).map(([variantCode, optionValue]) => ({
            variantCode,
            val: optionValue,
          })),
        );

        productVariant = state.variantsMatrix[productHash];

        if (!productVariant) {
          throw new ProductVariantsLookupError(
            `Failed to get Product from Options, unable to match Product by hash - ${productHash}!`,
          );
        }

        return productVariant;
      };
    },

    getOptionsFromProductHash: (state) => {
      return (productHash: string) => {
        const product = state.variantsMatrix[productHash];

        if (!product) {
          throw new ProductVariantsLookupError(
            `Failed to get Options from Product Hash - ${productHash}!`,
          );
        }

        return product.variantOptions.map(
          ({ variantCode, variantId }: { variantCode: string; variantId: string }) => ({
            variantCode,
            variantId,
          }),
        );
      };
    },

    getPricingAndAvailabilityByVariantSku: (state) => {
      return (sku: string) => {
        return (
          state.productPricingAndAvailability as ProductPricingAndAvailablityConfigurable
        ).variants.find((v) => v.sku === sku);
      };
    },

    getIsAnyProductVariantAvailable(state): () => boolean {
      return () =>
        (state.productPricingAndAvailability as ProductPricingAndAvailablityConfigurable)!.variants.some(
          (v) => v.isInStock,
        );
    },
  },
});
