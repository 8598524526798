import api, { APP_URL, CDN_URL, API_URL } from '../Api';

export const getProduct = (code: string) => {
  const apiRoot = document.location.host === APP_URL ? CDN_URL : API_URL; // TODO is this still needed?

  return api.get(`public/product/embed/${code}`, apiRoot);
};

export const getProductPricingAndAvailability = (code: string) => {
  const apiRoot = document.location.host === APP_URL ? CDN_URL : API_URL; // TODO is this still needed?

  return api.get(`public/product/embed/${code}/product`, apiRoot);
};
