<template>
  <dl class="information-tab tab-panel" :class="isTabOpen ? 'tab-open' : 'tab-closed'">
    <dt
      class="tab-trigger"
      tabindex="0"
      role="button"
      @keydown.enter="toggleTab()"
      @click="toggleTab()"
    >
      <svg width="23" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.013 4.578H3.48c-.741 0-1.343.601-1.343 1.343v8.952c0 .741.602 1.342 1.343 1.342h12.533c.741 0 1.343-.6 1.343-1.342V5.92c0-.742-.602-1.343-1.343-1.343ZM3.48 3.235A2.686 2.686 0 0 0 .795 5.921v8.952a2.686 2.686 0 0 0 2.685 2.685h12.533a2.685 2.685 0 0 0 2.685-2.685V5.92a2.686 2.686 0 0 0-2.685-2.686H3.48Z"
          fill="#171717"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.027 9.278H1.69V6.592h16.337v2.686Z"
          fill="#171717"
        />
        <circle cx="5.123" cy="12.934" fill="#171717" r="1.655" />
        <circle cx="6.995" cy="12.934" fill="#171717" r="1.655" />
        <path d="M23 15.323a5.584 5.584 0 1 1-11.168 0 5.584 5.584 0 0 1 11.168 0Z" fill="#fff" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.416 19.611a4.288 4.288 0 1 0 0-8.576 4.288 4.288 0 0 0 0 8.576Zm0 1.296a5.584 5.584 0 1 0 0-11.168 5.584 5.584 0 0 0 0 11.168Z"
          fill="#171717"
        />
        <path
          d="m16.6 16.067 2.455-2.641a.501.501 0 0 1 .741 0 .56.56 0 0 1 0 .755l-2.825 3.04-.37-1.154Zm0 0-.823-.886a.501.501 0 0 0-.741 0 .56.56 0 0 0 0 .755l1.193 1.285.147-.136.224-1.018Z"
          fill="#171717"
          stroke="#171717"
          stroke-width=".4"
        />
      </svg>

      <h4>How it Works?</h4>

      <IconArrow />
    </dt>
    <dd class="tab-content">
      <p>
        ShopThru brings you products from top retailers to your favourite sites and blogs. Use our
        secure checkout to buy products without leaving this site. Enjoy the latest in ecommerce
        security and integrations with PayPal, Apple Pay and Google Pay for convenient and safe
        payments. Ah! And no cookies or funny business.
      </p>
    </dd>
  </dl>
</template>

<script>
// Stores
import { mapState, mapWritableState } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useMainStore } from '@/stores/MainStore.ts';
import IconArrow from '@/components/icons/IconArrow.vue';

export default {
  name: 'InformationTab',
  components: { IconArrow },
  computed: {
    ...mapState(useAdvertiserStore, ['advertiserDelivery']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),

    isTabOpen() {
      return this.currentlyOpenInfoTab === 'PRODUCT_INFO';
    },
  },
  methods: {
    toggleTab() {
      if (this.currentlyOpenInfoTab === 'PRODUCT_INFO') {
        this.currentlyOpenInfoTab = undefined;
      } else {
        this.currentlyOpenInfoTab = 'PRODUCT_INFO';
      }
    },
  },
};
</script>
