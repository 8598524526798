<template>
  <div v-if="shippingMethods">
    <h4>Shipping Method</h4>
    <ul>
      <li v-for="(method, i) in shippingMethods" :key="method.code">
        <div class="input-field">
          <input
            type="radio"
            :id="method.code"
            name="shippingMethod"
            required
            :aria-labelledby="method.code + '-label'"
            @change="updateShippingMethod(method)"
            :data-test="`shipping-method-input-${i}`"
            :checked="method.code === cart.shipping?.selectedShippingMethod"
          />

          <label :for="method.code" :id="method.code + '-label'">
            {{ method.label }}
            <strong v-text="getShippingMethodsPrice(method.amount)"></strong>
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { useMainStore } from '../../stores/MainStore';

export default {
  name: 'ShippingMethods',
  computed: {
    ...mapState(useMainStore, ['currency']),
    ...mapState(useShippingStore, ['shippingMethods']),
    ...mapState(useCartStore, ['cart']),
    ...mapWritableState(useShippingStore, ['isShippingMethodSelectionInProgress']),
  },
  methods: {
    ...mapActions(useCartStore, ['setShippingMethod']),

    getShippingMethodsPrice(price) {
      // If price is 0 then return "Free"
      if (price === 0) return 'Free';

      // Else format price.
      return `${this.currency.symbol}${parseFloat(price).toFixed(2)}`;
    },

    async updateShippingMethod({ code }) {
      this.isShippingMethodSelectionInProgress = true;
      await this.setShippingMethod(code);
      this.isShippingMethodSelectionInProgress = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
