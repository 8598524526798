import type { JwtPayload } from 'jwt-decode';

export const exampleCompleteCart = {
  id: 'cart-id-123',
  shortCode: '16CBAAAB',
  productId: 'my-product-id',
  productQuantity: 1,
  isDiscountApplied: false,
  pricing: {
    unitPrice: 100,
    totalUnitPrice: 100,
    totalDiscount: 25,
    totalDiscountedPrice: 75,
    shippingPrice: 20,
    grandTotal: 95,
  },
  addresses: {
    shippingAddress: {
      name: 'Bob Smith',
      address1: 'My Shipping Address Line 1',
      address2: 'My Shipping Address Line 2',
      address3: 'My Shipping Address Line 3',
      city: 'My Shipping city',
      postalCode: 'My Shipping Postal Code',
      administrativeArea: 'My Shipping Administrative Area',
      countryCode: 'My Shipping Country Code',
    },
    billingAddress: {
      name: 'Bobby Smith',
      address1: 'My Billing Address Line 1',
      address2: 'My Billing Address Line 2',
      address3: 'My Billing Address Line 3',
      city: 'My Billing city',
      postalCode: 'My Billing Postal Code',
      administrativeArea: 'My Billing Administrative Area',
      countryCode: 'My Billing Country Code',
    },
    customer: {
      firstName: 'Bob',
      lastName: 'Smith',
      email: 'bob@example.com',
      phone: '+44123456',
    },
  },
  shipping: {
    selectedShippingMethod: 'flatrate_smrt_flatrate',
  },
};

export type CartJwtPayload = JwtPayload & {
  cart: object;
};

export type CartAddress = {
  streetAddress: string[];
  city: string;
  postalCode: string;
  region: string;
  country: string;
};

export type CartCustomer = {
  firstName: string;
  lastName: string;
  email: string;
};

export type CartShipping = {
  selectedShippingMethod: ToDo;
};

export type CartSelectedShippingMethod = ToDo;

export type CartInitialProps = {
  id: string;
  shortCode: string;
  productId: string;
};

export type CartPricedProps = CartInitialProps & {
  pricing: {
    unitPrice: number;
    totalUnitPrice: number;
    totalDiscount: number;
    totalDiscountedPrice: number;
    shippingPrice: number;
    grandTotal: number;
  };
  productQuantity: number;
  isDiscountApplied: boolean;
};

export type CartShippingWaitingProps = CartPricedProps & {
  addresses: {
    billingAddress: CartAddress;
    shippingAddress: CartAddress;
  };
  shipping: CartShipping;
};

export type CartShippingSetProps = CartShippingWaitingProps & {
  shipping: {
    selectedShippingMethod: ToDo;
  };
};

export type CartCompleteProps = CartShippingSetProps & {
  transactionId: string;
  confirmationEmailAddress: string;
};

export class CartInitial {
  id: string;
  shortCode: string;
  productId: string;

  constructor(props: CartInitialProps) {
    this.id = props.id;
    this.shortCode = props.shortCode;
    this.productId = props.productId;
  }
}

export class CartPriced extends CartInitial {
  productQuantity: number;
  isDiscountApplied: boolean;
  pricing: {
    unitPrice: number;
    totalUnitPrice: number;
    totalDiscount: number;
    totalDiscountedPrice: number;
    shippingPrice: number;
    grandTotal: number;
  };

  constructor(props: CartPricedProps) {
    super(props);

    this.productQuantity = props.productQuantity;
    this.pricing = props.pricing;
    this.isDiscountApplied = props.isDiscountApplied;
  }
}

export class CartShippingWaiting extends CartPriced {
  addresses: {
    billingAddress: CartAddress;
    shippingAddress: CartAddress;
  };

  constructor(props: CartShippingWaitingProps) {
    super(props);

    this.addresses = props.addresses;
  }
}

export class CartShippingSet extends CartShippingWaiting {
  shipping: {
    selectedShippingMethod: CartSelectedShippingMethod;
  };

  constructor(props: CartShippingSetProps) {
    super(props);

    this.shipping = props.shipping;
  }
}

export class CartComplete extends CartShippingSet {
  transactionId: string;
  confirmationEmailAddress: string;

  constructor(props: CartCompleteProps) {
    super(props);

    this.transactionId = props.transactionId;
    this.confirmationEmailAddress = props.confirmationEmailAddress;
  }
}

export type Cart =
  | CartInitial
  | CartPriced
  | CartShippingWaiting
  | CartShippingSet
  | CartComplete
  | null;
