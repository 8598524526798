<template>
  <article>
    <StickyProductSummary />

    <h2 class="mt-0" v-text="product.name"></h2>

    <span @click="openProductDescription()">
      See more details<svg width="11" height="6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m5.643 5.448-.35.358a.5.5 0 0 0 .7 0l-.35-.358ZM.989 1.603l4.305 4.203.698-.716L1.688.887l-.7.716Zm5.003 4.203 4.305-4.203-.699-.716L5.294 5.09l.698.716Z"
          fill="#0F3FA8"
        />
      </svg>
    </span>

    <div v-if="isShopFrontInitialised">
      <div id="product-selection-and-pricing-container">
        <div v-if="isProductHasVariants">
          <ProductOptions data-test="product-options" />
        </div>

        <div
          v-if="!this.isProductHasVariants || this.getIsProductOptionsSelectionValid"
          data-test="main-pricing-labelling"
        >
          <div class="price-and-qty-container" ref="pricingRef">
            <div
              class="price-container"
              data-test="price-container"
              :class="discountLookupState === 'DISCOUNT_APPLIED' ? 'sale-container' : ''"
            >
              <span class="price price--normal">
                <span
                  data-test="normal-price"
                  v-text="getFormattedPrice(getSelectedProductRegularPriceDisplay)"
                ></span>
              </span>

              <span class="price price--sale">
                <span
                  v-if="cart?.pricing?.totalDiscountedPrice"
                  data-test="discounted-price"
                  v-text="getFormattedPrice(cart.pricing.totalDiscountedPrice)"
                ></span>
              </span>
            </div>

            <ProductQty
              data-test="product-quantity-display"
              :quantity="cart?.productQuantity"
              :onIncrementQuantity="incrementQuantity"
              :onDecrementQuantity="decrementQuantity"
              :onSetQuantity="setQuantity"
            />
          </div>

          <div
            v-if="cart?.pricing && cart?.productQuantity > 1"
            class="total-container"
            data-test="totals-container"
            :class="isQuantityChangeInProgress ? 'text-loading' : ''"
          >
            <span
              class="price order-total"
              v-text="
                isQuantityChangeInProgress ? '' : 'Total: ' + getFormattedPrice(getTotalCartPrice)
              "
              data-test="totals-container-label"
            ></span>
          </div>
        </div>

        <div
          v-else-if="getIsProductOptionsSelectionComplete && !getIsProductOptionsSelectionValid"
          class="colour-danger"
          data-test="invalid-option-selection-message"
        >
          <span
            v-if="
              !getIsProductOptionsSelectionHasPricingData || !getIsProductOptionsSelectionInStock
            "
          >
            Sorry, that product selection is currently out of stock.
          </span>
          <span v-else>
            No products match your selected options, please try another combination.
          </span>
        </div>
      </div>
    </div>

    <div v-else>
      <lottie-animation :animationData="loaderAnimation" :loop="true" />
    </div>

    <div class="summary-text" v-if="advertiserSummaryText">
      {{ advertiserSummaryText }}
    </div>
  </article>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia';
import { polyfill, elementScrollIntoView } from 'seamless-scroll-polyfill';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useCartStore } from '@/stores/CartStore.ts';
import { useMainStore } from '@/stores/MainStore.ts';

import ProductQty from '@/components/ProductQty/index.vue';
import ProductOptions from '@/components/ProductOptions/index.vue';
import loader from '@/assets/loader.json';
import StickyProductSummary from '@/components/StickyProductSummary/index.vue';

export default {
  name: 'ProductComp',
  components: {
    ProductQty,
    ProductOptions,
    StickyProductSummary,
  },
  data() {
    return {
      pricingRef: null,
      intersectionObserver: null,
    };
  },
  computed: {
    ...mapState(useMainStore, ['isShopFrontInitialised']),
    ...mapState(useProductStore, ['product', 'discountLookupState', 'isProductHasVariants']),
    ...mapState(useAdvertiserStore, ['advertiserSummaryText']),
    ...mapState(useCartStore, [
      'cart',
      'getFormattedPrice',
      'getTotalCartPrice',
      'getIsProductOptionsSelectionComplete',
      'getIsProductOptionsSelectionValid',
      'getIsProductOptionsSelectionHasPricingData',
      'getIsProductOptionsSelectionInStock',
      'isQuantityChangeInProgress',
    ]),
    loaderAnimation() {
      return loader;
    },
    ...mapWritableState(useProductStore, ['isShowStickyProductSummary']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),

    getSelectedProductRegularPriceDisplay() {
      return window.Cypress ? 123 : this.getSelectedProductRegularPrice();
    },
  },
  methods: {
    ...mapActions(useCartStore, [
      'incrementQuantity',
      'decrementQuantity',
      'setQuantity',
      'getSelectedProductRegularPrice',
    ]),

    initIntersectionObserver() {
      this.disconnectIntersectionObserver();

      if (!this.$refs.pricingRef) {
        return;
      }

      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          this.isShowStickyProductSummary = !entries[0].isIntersecting;
        },
        { rootMargin: '-20% 0% 0% 0%' },
      );

      this.intersectionObserver.observe(this.$refs.pricingRef);
    },

    disconnectIntersectionObserver() {
      this.intersectionObserver?.disconnect();
    },

    openProductDescription() {
      polyfill();

      const element = document.getElementById('product-details-tab');

      this.currentlyOpenInfoTab = 'PRODUCT_DETAILS';

      if (element) {
        return elementScrollIntoView(element, { behavior: 'smooth' });
      }
    },
  },
  watch: {
    isShopFrontInitialised(newVal, oldVal) {
      if (!oldVal && newVal) {
        setTimeout(() => {
          this.initIntersectionObserver();
        }, 0);
      }
    },
    showPricingElements(newVal, oldVal) {
      if (!oldVal && newVal) {
        setTimeout(() => {
          this.initIntersectionObserver();
        }, 0);
      } else if (!newVal) {
        this.disconnectIntersectionObserver();
      }
    },
  },
  mounted() {
    this.initIntersectionObserver();
  },
  beforeUnmount() {
    this.disconnectIntersectionObserver();
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
