<template>
  <svg width="9" height="16" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8628 14.2293L4.75613 8.10925L10.8628 1.98925L8.98279 0.109253L0.982793 8.10925L8.98279 16.1093L10.8628 14.2293Z"
      fill="#fff"
    />
  </svg>
</template>
