<template>
  <fieldset>
    <div
      v-for="option in productUiOptions"
      :key="option.optionCode"
      class="input-field select-input"
    >
      <select
        v-model="selectedOptions[option.optionCode]"
        :name="option.optionCode"
        :id="`product-option-` + option.optionCode"
        required
        @change="onChangeOption"
      >
        <option disabled selected value="">{{ option.label }}</option>
        <option
          v-for="value in option.values"
          :key="value.id"
          :value="value.id"
          :disabled="!value.isCombinationAvailable"
          v-text="getOptionLabel(value)"
        ></option>
      </select>

      <label class="label" :for="`product-option-` + option.optionCode">
        {{ option.label }}
      </label>
      <IconArrow />
    </div>
  </fieldset>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';

import IconArrow from '@/components/icons/IconArrow.vue';

export default {
  name: 'ProductOptions',
  components: {
    IconArrow,
  },
  data() {
    return {
      selectedOptions: {},
    };
  },
  mounted() {
    // Set default selected values
    this.getProductUiOptions.forEach((uiOption) => {
      this.selectedOptions[uiOption.optionCode] = '-1';
    });
  },
  computed: {
    ...mapState(useProductStore, ['getProductUiOptions']),
    ...mapState(useCartStore, ['getIsProductOptionSelectionAvailable', 'combinationAvailability']),

    // Add empty/ default option to each drop down menu and set deffault value of isCombinationAvailable, where isCombinationAvailable
    // is a flag indicating whether the option should be enabled or disabled based on the seleciton of the other fields.
    productUiOptions() {
      return this.getProductUiOptions.map((variantOptions) => ({
        ...variantOptions,
        values: [
          {
            id: '-1',
            label: `Please select a ${variantOptions.label}`,
            isCombinationAvailable: true,
          },
        ].concat(
          variantOptions.values.map((val) => ({
            ...val,
            isCombinationAvailable:
              this.combinationAvailability[variantOptions.optionCode][val.id].isAvailable,
          })),
        ),
      }));
    },
  },
  methods: {
    ...mapActions(useMainStore, ['currency']),
    ...mapActions(useShippingStore, ['resetShippingMethods']),
    ...mapActions(useCartStore, ['setProductOptions']),

    /**
     * Return Option with price label if price is greater than 0
     */
    getOptionLabel(option) {
      let price = null;

      if (option.price && option.price > 0) {
        price = option.price;
      }

      return option.label + (price ? ' ( +' + this.formatPrice(price) + ' )' : '');
    },

    formatPrice(price) {
      return `${this.currency.symbol}${parseFloat(price).toFixed(2)}`;
    },

    onChangeOption(e) {
      this.resetShippingMethods();

      this.setProductOptions(
        Object.entries(this.selectedOptions).map(([variantCode, optionValue]) => ({
          variantCode,
          optionValue,
        })),
      );
    },
  },
};
</script>
