import { createWebHistory, createRouter } from 'vue-router';
import Product from '@/views/Product.vue';
import Shipping from '@/views/Shipping.vue';
import Checkout from '@/views/Checkout.vue';
import ErrorCatchAll from '@/views/Error.vue';
import Thankyou from '@/views/Thankyou.vue';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';

const routes = [
  {
    path: '/:product',
    name: 'Product',
    component: Product,
    meta: { gtm: 'Product Page' },
  },
  {
    path: '/shipping/:product',
    name: 'Shipping',
    component: Shipping,
    meta: { gtm: 'Shipping Page' },
  },
  {
    path: '/checkout/:product',
    name: 'Checkout',
    component: Checkout,
    meta: { gtm: 'Checkout Page' },
  },
  {
    path: '/error/:product',
    name: 'Error',
    component: ErrorCatchAll,
    meta: { gtm: 'Error Catch All' },
  },
  {
    path: '/thankyou/:product',
    name: 'Thankyou',
    component: Thankyou,
    meta: { gtm: 'Thankyou Page' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    const element = document.getElementById('app');
    if (element) return elementScrollIntoView(element, { behavior: 'smooth' });
  },
});

export default router;
