import { useMediaQuery } from '@vueuse/core';
import sassVariables from '@/styles/global/_jsVariables.module.scss';

enum ScreenSizeEnum {
  'xxs',
  'xs',
  's',
  'm',
  'l',
}

type ScreenSize = keyof typeof ScreenSizeEnum;

// As per the variables defined in styles/utility/_variables.scss
const sizeQueries: Record<ScreenSize, string> = {
  xxs: `(min-width: ${sassVariables.screenWidth_xxs})`,
  xs: `(min-width: ${sassVariables.screenWidth_xs})`,
  s: `(min-width: ${sassVariables.screenWidth_s})`,
  m: `(min-width: ${sassVariables.screenWidth_m})`,
  l: `(min-width: ${sassVariables.screenWidth_l})`,
};

export default (app: ToDo) => {
  // Keeping as a hint in case ever required one day
  // app.provide('isScreenSizeAtLeast', () => ...);

  app.config.globalProperties.$isScreenSizeAtLeast = (size: ScreenSize) => {
    if (!(size in ScreenSizeEnum)) {
      throw new Error(`Invalid size ${size} specified in call to isScreenSizeAtLeast()!`);
    }

    return useMediaQuery(sizeQueries[size]).value;
  };
};
