<template>
  <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 1.52 12.59.11 7 5.7 1.41.11 0 1.52l5.59 5.59L0 12.7l1.41 1.41L7 8.52l5.59 5.59L14 12.7 8.41 7.11 14 1.52Z"
      fill="#000"
    />
  </svg>
</template>
