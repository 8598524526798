<template>
  <dl
    class="product-details-tab tab-panel"
    id="product-details-tab"
    :class="isTabOpen ? 'tab-open' : 'tab-closed'"
  >
    <dt
      class="tab-trigger"
      aria-labelledby="product-details-h4"
      tabindex="0"
      role="button"
      @keydown.enter="
        toggleTab();
        readMore = false;
      "
      @click="
        toggleTab();
        readMore = false;
      "
    >
      <svg width="22" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.735 4.858H7.725a.203.203 0 0 0-.203.202v1.416c0 .11.091.202.202.202h12.011a.203.203 0 0 0 .203-.202V5.06a.203.203 0 0 0-.203-.202Zm0 5.803H7.725a.203.203 0 0 0-.203.203v1.415c0 .111.091.202.202.202h12.011a.203.203 0 0 0 .203-.202v-1.415a.203.203 0 0 0-.203-.203Zm0 5.804H7.725a.203.203 0 0 0-.203.202v1.415c0 .111.091.203.202.203h12.011a.203.203 0 0 0 .203-.203v-1.415a.203.203 0 0 0-.203-.202ZM2.063 5.768a1.416 1.416 0 1 0 2.83 0 1.416 1.416 0 0 0-2.83 0Zm0 5.803a1.416 1.416 0 1 0 2.831 0 1.416 1.416 0 0 0-2.832 0Zm0 5.804a1.416 1.416 0 1 0 2.831 0 1.416 1.416 0 0 0-2.832 0Z"
          fill="#171717"
        />
      </svg>
      <h4 id="product-details-h4">Product Details</h4>
      <IconArrow />
    </dt>
    <dd class="tab-content" :class="readMore && isTabOpen ? 'read-open' : ''">
      <div v-html="product.description"></div>
      <button
        v-if="displayedTextLength"
        tabindex="1"
        class="button button--full-width read-more-btn"
        @click="readMore = !readMore"
      >
        <span v-if="readMore">Read Less</span>
        <span v-else>Read More</span>
      </button>
    </dd>
  </dl>
</template>

<script>
// Stores
import { mapState, mapWritableState } from 'pinia';
import { useProductStore } from '@/stores/ProductStore.ts';
import IconArrow from '@/components/icons/IconArrow.vue';
import { useMainStore } from '@/stores/MainStore';

export default {
  name: 'ProductDelivery',
  components: {
    IconArrow,
  },
  computed: {
    ...mapState(useProductStore, ['product']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),

    displayedTextLength() {
      return this.product.description?.length >= 200;
    },

    isTabOpen() {
      return this.currentlyOpenInfoTab === 'PRODUCT_DETAILS';
    },
  },
  data: () => ({
    readMore: false,
  }),
  methods: {
    toggleTab() {
      if (this.currentlyOpenInfoTab === 'PRODUCT_DETAILS') {
        this.currentlyOpenInfoTab = undefined;
      } else {
        this.currentlyOpenInfoTab = 'PRODUCT_DETAILS';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
