<template>
  <div class="copyright">
    <p class="secondary-text">© 2022 {{ companyName }}</p>
  </div>
</template>

<script>
// Stores
import { mapState } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';

export default {
  name: 'Copyright',
  computed: {
    ...mapState(useAdvertiserStore, ['companyName']),
  },
};
</script>
