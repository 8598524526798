<template>
  <div id="adyen-dropin-container" />
</template>
<script>
// stores
import { mapActions, mapState } from 'pinia';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore';
import { useMainStore } from '@/stores/MainStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

export default {
  name: 'AdyenDropIn',
  data() {
    return {
      errorMessage: '',
      orderId: null,
      agreementLocation: '',
      clientKey: '',
      paymentMethods: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ['currency']),
    ...mapState(usePaymentStore, ['paymentData', 'totals', 'transaction']),
    ...mapState(useShippingStore, ['address']),
    ...mapState(useCartStore, ['cart']),
  },

  async created() {
    const paymentMethodsResponse = JSON.parse(JSON.stringify(this.transaction.adyenPaymentMethods));
    const clientKey = this.transaction.clientKey;
    const configuration = {
      paymentMethodsResponse: { paymentMethods: paymentMethodsResponse },
      clientKey,
      locale: this.address.billing.city,
      environment: this.transaction.environment,
      analytics: {
        enabled: false,
      },
      amount: {
        value: this.totals.grand * 100,
        currency: this.currency.currencyCode,
      },
      onError: (error, component) => {
        this.paymentFailure(error);
      },
      onSubmit: (state, dropin) => {
        dropin.setState('loading');
        this.placeOrder(state);
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: false,
          holderNameRequired: false,
          enableStoreDetails: false,
          hideCVC: false,
          name: 'Your card details',
        },
        threeDS2: {
          challengeWindowSize: '05',
        },
      },
    };
    const adyenCheckout = await AdyenCheckout(configuration);
    this.checkout = adyenCheckout.create('dropin', {
      setStatusAutomatically: false,
      onSelect: this.onSelect,
    });
    this.checkout.mount('#adyen-dropin-container');
  },
  beforeUnmount() {
    if (this.checkout) {
      this.checkout.unmount();
    }
  },
  methods: {
    ...mapActions(usePaymentStore, ['getAdyenPaymentMethod', 'placeOrder']),
    ...mapActions(useMainStore, ['setErrorNotification']),

    onSelect() {
      this.agreementLocation = '';
      // Timeout of 0 as this element doesn't exist at the point onSelect is called by Adyen.
      setTimeout(() => {
        this.agreementLocation = '.adyen-checkout__payment-method--selected';
      }, 0);
    },

    /**
     * Set error notification and inform the customer to restart
     */
    paymentFailure(err) {
      this.loading = false;
      // TODO use setPaymentErrorNotification()
      this.setErrorNotification({
        title: 'The payment was unsuccessful',
        content: 'Unfortunately the order could not be placed. Please try again.',
        actionText: 'Try A Different Payment Method',
        err,
      });
    },
  },
};
</script>
