<template>
  <div class="product-view">
    <!-- Main hero image and gallery -->
    <div class="product-imagery-container">
      <div class="product-imagery-container__product-gallery">
        <ProductGallery />
      </div>
    </div>

    <!-- Product headline and pricing info -->
    <div class="product-headline-and-pricing-container">
      <div class="product-info">
        <Product />
      </div>
    </div>

    <!-- Discount button, payment buttons and product details -->
    <div v-if="isShopFrontInitialised" class="product-buttons-and-detail-container">
      <div
        v-if="
          !isProductHasVariants ||
          (getIsProductOptionsSelectionComplete && getIsProductOptionsSelectionValid)
        "
      >
        <div v-if="isShowDiscountUiDevice" id="product-promocode-container">
          <Promocode
            :key="promocodeComponentKey"
            data-test="promocode-device"
            :doReloadComponent="resetDiscountDevice"
          />
        </div>

        <PaymentButtons />
      </div>

      <ProductFeatures />
      <ProductDetails />
      <ProductDelivery />
      <InformationTab />
      <TermsTab />
    </div>
  </div>

  <Footer />
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useProductStore } from '@/stores/ProductStore.ts';
import { usePublisherStore } from '@/stores/PublisherStore.js';
import gtmTracker from '@/helpers/googleTagManager.ts';
import Product from '@/components/Product/index.vue';
import ProductGallery from '@/components/ProductGallery/index.vue';
import ProductFeatures from '@/components/ProductFeatures/index.vue';
import ProductDetails from '@/components/ProductDetails/index.vue';
import ProductDelivery from '@/components/ProductDelivery/index.vue';
import PaymentButtons from '@/components/Payment/index.vue';
import Promocode from '@/components/Promocode/index.vue';
import InformationTab from '@/components/InformationTab/index.vue';
import TermsTab from '@/components/Terms/index.vue';
import Footer from '@/components/Footer/index.vue';

export default {
  components: {
    InformationTab,
    TermsTab,
    Product,
    ProductGallery,
    ProductFeatures,
    ProductDetails,
    ProductDelivery,
    PaymentButtons,
    Promocode,
    Footer,
  },
  data() {
    return {
      promocodeComponentKey: 0,
    };
  },
  computed: {
    ...mapState(useMainStore, ['isShopFrontInitialised']),
    ...mapState(useCartStore, [
      'cart',
      'getIsProductOptionsSelectionComplete',
      'getIsProductOptionsSelectionValid',
      'isDiscountRevoked',
    ]),
    ...mapState(useAdvertiserStore, ['advertiserName', 'companyName']),
    ...mapState(useProductStore, ['product', 'isProductHasVariants', 'discountLookupState']),
    ...mapState(usePublisherStore, ['publisherUrl']),

    ...mapWritableState(useCartStore, ['isDiscountRevoked']),

    isShowDiscountUiDevice() {
      if (this.isProductHasVariants) {
        return this.getIsProductOptionsSelectionComplete;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions(useCartStore, ['getSelectedProductRegularPrice']),
    ...mapActions(useProductStore, ['setDiscountLookupState']),

    resetDiscountDevice() {
      // We are using the 'Key-Changing Technique' to simply a ref-render the Promocode component afresh.
      this.setDiscountLookupState(null);
      this.promocodeComponentKey += 1;
    },
  },
  mounted() {
    gtmTracker.trackJourneyEvent({
      action: 'view_product',
      label: 'Product has been loaded',
      product_id: this.product.id,
      product_name: this.product.name,
      price: this.getSelectedProductRegularPrice,
      advertiser_name: this.advertiserName,
      company_name: this.companyName,
      noninteraction: false,
      publisherUrl: this.publisherUrl,
    });
  },
  watch: {
    cart(newVal, oldVal) {
      /**
       * Discount revoked is inferred by sensing when a discount has been applied but then removed in a subsequent cart change
       * (e.g. the original discount is no longer applicable based on the new cart contents).
       */
      const discountStatePreviouslySet = !!oldVal && !!newVal && oldVal.isDiscountApplied !== null;
      const isDiscountRevoked =
        discountStatePreviouslySet &&
        newVal.isDiscountApplied === false &&
        oldVal.isDiscountApplied === true;

      if (isDiscountRevoked) {
        this.setDiscountLookupState('DISCOUNT_REVOKED');
      }

      this.isDiscountRevoked = isDiscountRevoked;
    },
    discountLookupState(newVal) {
      if (newVal === 'LOOKUP_ERRED') {
        // this.resetDiscountDevice();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Product.scss';
</style>
