import { defineStore } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';
import { CartComplete } from '@/types/cart.types';
import { getRouter } from '@/router';

type State = {
  totals: {
    subtotal: number;
    shipping: number;
    grand: number;
  };
  supports: {
    paymentRequest: boolean;
    payPal: boolean;
  };
  payment: {
    nonce: string;
    method: string;
  };
  transaction: {
    token: string | null;
    clientKey: string;
    adyenPaymentMethods: ToDo;
    environment: string;
  };
  paymentData: {
    nonce: string;
    type: string;
    niceType: string;
    last4: string;
    method: string;
    bin: string | null;
  };
  orderId: string | null;
};

const getInitialState = (): State => ({
  totals: {
    subtotal: 0,
    shipping: 0,
    grand: 0,
  },
  supports: {
    paymentRequest: typeof window.PaymentRequest === 'function',
    payPal: true,
  },
  payment: {
    nonce: '',
    method: '',
  },
  transaction: {
    token: null,
    clientKey: '',
    adyenPaymentMethods: {},
    environment: '',
  },
  paymentData: {
    nonce: '',
    type: '',
    niceType: '',
    last4: '',
    method: '',
    bin: null,
  },
  orderId: null,
});

export const usePaymentStore = defineStore('paymentStore', {
  state: (): State => {
    return getInitialState();
  },
  actions: {
    setTransactionToken(token: string) {
      this.transaction.token = token;
    },

    setAdyenDetails(clientKey, paymentMethods) {
      this.transaction.clientKey = clientKey;
      this.transaction.adyenPaymentMethods = paymentMethods;
    },

    setEnvironmentType(environment: string) {
      this.transaction.environment = environment;
    },

    setPaymentData(data) {
      this.paymentData = data;
    },

    getAdyenPaymentMethod(state) {
      const method = state.data.paymentMethod.type === 'scheme' ? 'adyen_cc' : 'adyen_hpp';
      return {
        method,
        additional_data: {
          brand_code: state.data.paymentMethod.type,
          stateData: JSON.stringify(state.data),
        },
      };
    },

    formatPrice(price: string | number) {
      const mainStore = useMainStore();

      return `${mainStore.currency.symbol}${parseFloat(price).toFixed(2)}`;
    },

    setPaymentNonce(value: string) {
      this.paymentData.nonce = value;
    },

    async placeOrder() {
      const mainStore = useMainStore();
      const cartStore = useCartStore();
      const router = await getRouter();

      mainStore.startAppLoading();

      // if (paymentProvider === 'adyen') {
      //   payload = this.getAdyenPaymentMethod(state) as ToDo;

      try {
        await cartStore.setOrdered({
          nonce: this.paymentData.nonce,
          method: this.paymentData.method,
        });

        const cart = cartStore.cart as CartComplete;

        this.orderId = cart!.transactionId;

        mainStore.redirectToView('/thankyou', router.replace);

        mainStore.endAppLoading();
      } catch (err) {
        mainStore.endAppLoading();
        throw err;
      }
    },
  },
});
