<template>
  <div ref="googlePayContainer" id="google-pay-container" style="height: 50px"></div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { isBraintreePaymentAvailable } from '@/helpers/braintreePayments.ts';
import gtmTracker from '@/helpers/googleTagManager.ts';
import { GooglePaymentError } from '@/types/errors.types.ts';
import constants from '@/constants';

export default {
  name: 'BraintreeGooglePay',
  props: {
    googleApi: {},
    braintreeApi: {},
  },
  data() {
    return {
      googlePaymentInstance: null,
    };
  },
  computed: {
    ...mapState(useMainStore, ['currency']),
    ...mapState(usePaymentStore, ['transaction']),
    ...mapState(useShippingStore, ['address', 'shippingMethods']),
    ...mapState(useCartStore, ['cart', 'getTotalPaymentPrice']),
  },
  async mounted() {
    this.init();
  },
  methods: {
    ...mapActions(useMainStore, ['goToErrorPage', 'setPaymentButtonInitialised']),
    ...mapActions(useCartStore, ['setShippingMethod']),
    ...mapActions(usePaymentStore, ['setPaymentData', 'placeOrder']),
    ...mapActions(useShippingStore, ['fetchShippingMethods', 'setCustomer', 'setAddress']),

    onPaymentError(error) {
      const errorFn = () => {
        this.goToErrorPage({
          error,
        });
      };

      if (this.googlePaymentInstance?.teardown) {
        return this.googlePaymentInstance.teardown(errorFn);
      } else {
        errorFn();
      }
    },

    async init() {
      try {
        const thisVue = this;
        const googlePayContainer = this.$refs.googlePayContainer;

        if (!googlePayContainer) return; // TODO neeeded?

        const paymentsClientProps = {
          environment: ['local', 'dev'].includes(import.meta.env.VITE_APP_ENV)
            ? 'TEST'
            : 'PRODUCTION',
          paymentDataCallbacks: {
            onPaymentDataChanged: async (intermediatePaymentData) => {
              try {
                const result = await this.onPaymentDataChanged(intermediatePaymentData);
                return result;
              } catch (err) {
                return this.onPaymentError(err);
              }
            },
            onPaymentAuthorized: async () => {
              try {
                const result = await this.onPaymentAuthorized();
                return result;
              } catch (err) {
                return this.onPaymentError(err);
              }
            },
          },
        };

        let paymentsClient;

        if (window.Cypress) {
          paymentsClient = this.googleApi.payments.api.PaymentsClient;
          paymentsClient.initStubs(paymentsClientProps);
        } else {
          paymentsClient = new this.googleApi.payments.api.PaymentsClient(paymentsClientProps);
        }

        let button = paymentsClient.createButton({
          buttonColor: 'black',
          buttonType: 'buy',
          buttonSizeMode: 'fill',
        });

        const braintreeApiClient = await this.braintreeApi.client.create({
          authorization: this.transaction.token,
        });

        // throws
        isBraintreePaymentAvailable(braintreeApiClient, 'androidPay');

        googlePayContainer.appendChild(button);

        const googlePaymentInstance = await this.braintreeApi.googlePayment.create({
          client: braintreeApiClient,
          googlePayVersion: 2,
        });

        thisVue.googlePaymentInstance = googlePaymentInstance;

        const isReadyToPayResponse = await paymentsClient.isReadyToPay({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods:
            googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
          existingPaymentMethodRequired: true, // Optional
        });

        if (isReadyToPayResponse) {
          // TODO error case  if !isReadyToPayResponse
          button.addEventListener('click', (event) => {
            this.onSubmit({
              event,
              googlePaymentInstance,
              paymentsClient,
            });
          });
        }

        this.setPaymentButtonInitialised('google');
      } catch (err) {
        throw new GooglePaymentError('Error in init()!', err);
      }
    },

    async onPaymentDataChanged(intermediatePaymentData) {
      try {
        const storeCurrency = this.currency;

        let paymentDataRequestUpdate = {};
        let newShippingOptions = [];
        let shippingOptionData = intermediatePaymentData.shippingOptionData;

        let address = {
          streetAddress: [''],
          city: intermediatePaymentData.shippingAddress.locality,
          region: intermediatePaymentData.shippingAddress.administrativeArea,
          postalCode: intermediatePaymentData.shippingAddress.postalCode,
          country: intermediatePaymentData.shippingAddress.countryCode,
        };

        this.setAddress('billing', address);
        this.setAddress('shipping', address);

        await this.fetchShippingMethods();

        (this.shippingMethods || []).forEach((method) => {
          newShippingOptions.push({
            id: method.code,
            label: `${method.label} (${method.amount} ${storeCurrency.currencyCode})`,
            description: '',
          });
        });

        const currentShipping =
          shippingOptionData.id === 'shipping_option_unselected'
            ? newShippingOptions[0].id
            : shippingOptionData.id;

        // Set shipping methods in google modal
        paymentDataRequestUpdate.newShippingOptionParameters = {
          defaultSelectedOptionId: currentShipping,
          shippingOptions: newShippingOptions,
        };

        // Update Shipping code to selected code
        await this.setShippingMethod(currentShipping);

        paymentDataRequestUpdate.newTransactionInfo = {
          displayItems: [
            {
              label: 'Shipping',
              type: 'LINE_ITEM',
              price: (this.cart.pricing?.shippingPrice || 0).toString(),
              status: 'FINAL',
            },
          ],
          countryCode: constants.PAYMENT_PROCESSING_COUNTRY_CODE,
          currencyCode: storeCurrency.currencyCode,
          totalPriceStatus: 'FINAL',
          totalPrice: (this.getTotalPaymentPrice || 0).toString(),
          totalPriceLabel: 'Total',
        };

        return paymentDataRequestUpdate;
      } catch (err) {
        throw new GooglePaymentError('Error in onPaymentDataChanged()!', err);
      }
    },

    async onPaymentAuthorized() {
      try {
        return Promise.resolve({ transactionState: 'SUCCESS' });
      } catch (err) {
        throw new GooglePaymentError('Error in onPaymentAuthorized()!', err);
      }
    },

    // @see https://developers.google.com/pay/api/web/reference/response-objects#PaymentData
    handleGooglePayPayload(data) {
      try {
        const shipping = data.shippingAddress;
        const billing = data.paymentMethodData.info.billingAddress;

        const shippingAddress = {
          streetAddress: [shipping.address1, shipping.address2 ?? '', shipping.address3 ?? ''],
          city: shipping.locality,
          postalCode: shipping.postalCode,
          region: shipping.administrativeArea,
          country: shipping.countryCode,
        };

        const billingAddress = {
          streetAddress: [billing.address1, billing.address2 ?? '', billing.address3 ?? ''],
          city: billing.locality,
          postalCode: billing.postalCode,
          region: billing.administrativeArea,
          country: billing.countryCode,
        };

        this.setCustomer('shipping', {
          firstName: shipping.name,
          lastName: shipping.name,
        });

        this.setCustomer('billing', {
          firstName: billing.name,
          lastName: billing.name,
          phone: billing.phoneNumber,
          email: data.email,
        });

        this.setAddress('shipping', shippingAddress);
        this.setAddress('billing', billingAddress);
      } catch (err) {
        throw new GooglePaymentError('Error in handleGooglePayPayload()!', err);
      }
    },

    async onSubmit({ event, googlePaymentInstance, paymentsClient }) {
      try {
        const storeCurrency = this.currency;

        event.preventDefault();

        const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
          transactionInfo: {
            countryCode: constants.PAYMENT_PROCESSING_COUNTRY_CODE,
            currencyCode: storeCurrency.currencyCode,
            totalPriceStatus: 'ESTIMATED',
            totalPrice: (this.getTotalPaymentPrice || 0).toString(),
          },
          shippingAddressRequired: true,
          shippingOptionRequired: true,
          emailRequired: true,
          callbackIntents: ['PAYMENT_AUTHORIZATION', 'SHIPPING_ADDRESS', 'SHIPPING_OPTION'],
        });

        // We recommend collecting billing address information, at minimum
        // billing postal code, and passing that billing postal code with all
        // Google Pay card transactions as a best practice.
        // See all available options at https://developers.google.com/pay/api/web/reference/object
        const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];

        cardPaymentMethod.parameters.billingAddressRequired = true;
        cardPaymentMethod.parameters.billingAddressParameters = {
          format: 'FULL',
          phoneNumberRequired: true,
        };

        const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);

        this.handleGooglePayPayload(paymentData);

        const parsedPaymentData = await this.googlePaymentInstance.parseResponse(paymentData);

        await this.placeGooglePayOrder(parsedPaymentData);

        gtmTracker.doPlaceOrderGtmEvent({
          paymentType: 'GooglePay',
        });
      } catch (err) {
        const errorMsgWhiteList = ['User closed the Payment Request UI'];

        if (!errorMsgWhiteList.some((listItem) => err.message?.includes(listItem))) {
          throw new GooglePaymentError('Error in onSubmit()!', err);
        }
      }
    },

    async placeGooglePayOrder(data) {
      try {
        // Setting the nonce and payment method name
        this.setPaymentData({
          nonce: data.nonce,
          method: 'googlepay',
        });

        await this.placeOrder();
      } catch (err) {
        throw new GooglePaymentError('Error in placeGooglePayOrder()!', err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
