import axios from 'axios';

export const API_URL = `https://${import.meta.env.VITE_API_HOST}${import.meta.env.VITE_API_ROOT}`;
export const CDN_URL = `https://${import.meta.env.VITE_APP_URL}`;
export const APP_URL = `https://${import.meta.env.VITE_APP_URL}`;

const api = {
  get(uri, apiRoot = API_URL) {
    return axios.get(`${apiRoot}/${uri}`);
  },

  post(uri, data = {}, options = {}, apiRoot = API_URL) {
    return axios.post(`${apiRoot}/${uri}`, data, options);
  },
};

axios.interceptors.response.use(
  (response) => {
    // Modify the response data here
    return response;
  },
  (error) => {
    // Handle response errors
    return Promise.reject(error);
  },
);

export { api as default };
