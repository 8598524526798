<template>
  <div class="notification notification--error">
    <div class="notification__icon mb-5">
      <lottie-animation :animationData="errorAnimation" :loop="true" />
    </div>

    <div class="notification-text">
      <h2 v-text="message.title" data-test="error-msg-title" />

      <p v-html="message.subTitle" class="font-weight-light" data-test="error-msg-subtitle" />
    </div>

    <!-- Buttons -->
    <div v-for="(btn, i) in actionButtons" :key="`button_${i}`" class="mt-15">
      <button
        type="button"
        @click="btn.onClick"
        :class="{
          button: true,
          'button--primary': btn.priority === 'primary',
          'button--secondary': btn.priority === 'secondary',
          'button--tertiary': btn.priority === 'tertiary',
        }"
        data-test="error-action-button"
      >
        {{ btn.text }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { getRouter } from '@/router';
import * as Sentry from '@sentry/vue';
import errorAnimation from '@/assets/lottie/error.json';
import {
  ErrorBoundaryPageInferredError,
  ErrorBoundaryPageGenericError,
} from '@/types/errors.types.ts';
import { useMainStore } from '@/stores/MainStore.ts';

/**
 * This file is intended to have minimal application dependancies in notifying the user of an error (that has broken the checkout journey).
 * This is done so that we don't make calls to things like Stores where the original error may have occured and end up in a cyclic state.
 */
export default {
  name: 'Error',
  mounted() {
    const isSkipLogToSentry = this.$route.query.isSkipLogToSentry;

    if (!isSkipLogToSentry) {
      /**
       * Most of the time we'll arrive on this page from the global error boundary, where the Sentry log will already have been made.
       * However if we've come here explicitly via some other router call then send what error information we get from the URL.
       */
      Sentry.captureException(new ErrorBoundaryPageInferredError(JSON.stringify(this.error)));
    }

    this.endAppLoading();

    if (['local', 'dev', 'staging'].includes(import.meta.env.VITE_APP_ENV)) {
      console.info('Dev note - Error page reached with error:', this.error);
    }

    if (!this.fallbackUrl) {
      const msg = 'Could not find fallback URL on reaching Error page!';
      console.error(msg);
      Sentry.captureException(msg);
    }
  },
  computed: {
    ...mapState(useMainStore, ['fallbackUrl']),

    isPaymentError() {
      return [
        'GooglePaymentError',
        'ApplePaymentError',
        'PayPalPaymentError',
        'HostedFieldsPaymentError',
      ].includes(this.error?.name);
    },

    isOutOfStockError() {
      return ['OutOfStockError'].includes(this.error?.name);
    },

    errorAnimation() {
      return errorAnimation;
    },

    error() {
      try {
        const error = this.$route.query.error;

        if (error) {
          return JSON.parse(error);
        }
      } catch (err) {
        console.error('Failed to parse error!');
        Sentry.captureException(err);
      }
    },

    message() {
      const rtn = {
        title: 'Something has gone wrong!',
        subTitle: 'We apologise for the inconvenience.',
      };

      // Payment errors
      if (this.isPaymentError) {
        rtn.title = 'Your payment was unsuccessful';
        rtn.subTitle =
          'Unfortunately there was a problem processing your payment and your order could not be placed.<br /><br />Please try again or visit the retailer.';
      }

      // Out of stock errors
      if (this.isOutOfStockError) {
        rtn.title = 'Oh no, this item is out of stock!';
        rtn.subTitle =
          'Unfortunately the items you ordered are now out of stock, you can find alternatives directly from the retailer.';
      }

      return rtn;
    },

    actionButtons() {
      // Out of Stock Error
      if (this.isOutOfStockError) {
        return [
          {
            onClick: this.goToFallbackUrl,
            priority: 'secondary',
            text: 'Go direct to retailer',
          },
        ];
      }
      // Payment Error or Generic Error
      else {
        return [
          {
            onClick: this.restartCheckout,
            priority: 'secondary',
            text: 'Restart checkout',
          },
          {
            onClick: this.goToFallbackUrl,
            priority: 'tertiary',
            text: 'Go to retailer',
          },
        ];
      }
    },
  },

  methods: {
    ...mapActions(useMainStore, ['endAppLoading']),

    goToFallbackUrl() {
      if (this.fallbackUrl) {
        try {
          window.open(this.fallbackUrl, '_blank');
        } catch (err) {
          console.error('Failed to open fallback URL!');
          Sentry.captureException(err);
          return;
        }
      }

      try {
        parent.window.postMessage('closeCheckout', '*');
      } catch (err) {
        console.error('Failed to close checkout!');
        Sentry.captureException(err);
      }
    },

    async restartCheckout() {
      const router = await getRouter();
      const shortCode = this.$route.query.shortCode;

      if (shortCode) {
        return router.replace(`/${shortCode}`);
      }

      const failureMsg = 'Could not find short code to restart checkout!';

      console.error(failureMsg);
      Sentry.captureException(new ErrorBoundaryPageGenericError(failureMsg));
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Notification.scss';
</style>
