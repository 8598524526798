<template>
  <dl class="product-details-tab tab-panel" :class="isTabOpen ? 'tab-open' : 'tab-closed'">
    <dt
      class="tab-trigger"
      tabindex="0"
      role="button"
      @keydown.enter="toggleTab()"
      @click="toggleTab()"
    >
      <svg width="22" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="m21.25 10.807-1.918-2.173a2.964 2.964 0 0 0-2.214-1H14.2V5.398a.682.682 0 0 0-.681-.7H.854a.682.682 0 0 0-.681.682v11.218c0 .376.305.681.681.681H2.22a2.627 2.627 0 0 0 5.072 0h7.591a2.627 2.627 0 0 0 5.073 0h1.363a.682.682 0 0 0 .682-.681v-3.832a2.96 2.96 0 0 0-.75-1.96ZM1.537 6.062h11.3v9.854H7.29a.086.086 0 0 1 0-.032 2.908 2.908 0 0 0-.127-.35l-.027-.06a2.47 2.47 0 0 0-.182-.322 2.938 2.938 0 0 0-.236-.295l-.046-.055a3.505 3.505 0 0 0-.272-.245l-.091-.06a2.48 2.48 0 0 0-.664-.363l-.064-.023a2.435 2.435 0 0 0-.368-.095h-.068a2.386 2.386 0 0 0-.405-.036c-.134 0-.267.012-.4.036H4.27a2.377 2.377 0 0 0-.364.095l-.068.023a2.215 2.215 0 0 0-.35.16c-.109.06-.214.129-.314.204l-.045.04a2.776 2.776 0 0 0-.268.246l-.05.055a2.308 2.308 0 0 0-.414.623l-.027.054a2.347 2.347 0 0 0-.127.354v.032h-.705v-9.84ZM3.58 17.096a1.268 1.268 0 1 0 2.344-.97 1.268 1.268 0 0 0-2.344.97Zm13.835.783a1.268 1.268 0 1 1-.01-2.535 1.268 1.268 0 0 1 .01 2.535Zm2.531-1.945h.682v-3.168a1.577 1.577 0 0 0-.4-1.055L18.31 9.54a1.595 1.595 0 0 0-1.19-.541H14.2v6.936h.728a.09.09 0 0 0 0-.032c.034-.12.077-.236.127-.35l.027-.059c.052-.112.113-.22.182-.323a2.93 2.93 0 0 1 .236-.295l.046-.055c.086-.086.177-.168.272-.245l.055-.04a2.47 2.47 0 0 1 .664-.364l.063-.023c.12-.042.243-.074.368-.096h.069c.133-.023.268-.035.404-.036.134 0 .268.013.4.036h.073c.123.022.245.054.363.096l.069.023c.12.042.238.096.35.159.108.06.213.13.313.204l.055.041c.094.076.184.158.268.245l.05.055c.083.093.161.192.232.295.067.103.126.211.177.323l.027.055c.052.114.095.233.127.354v.032Z"
          fill="#171717"
        />
      </svg>
      <h4>Shipping &amp; Returns</h4>
      <IconArrow />
    </dt>
    <dd class="tab-content">
      <p v-text="advertiserDelivery"></p>
    </dd>
  </dl>
</template>

<script>
// Stores
import { mapState, mapWritableState } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import IconArrow from '@/components/icons/IconArrow.vue';
import { useMainStore } from '@/stores/MainStore';

export default {
  name: 'ProductDelivery',
  components: { IconArrow },
  computed: {
    ...mapState(useAdvertiserStore, ['advertiserDelivery']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),

    isTabOpen() {
      return this.currentlyOpenInfoTab === 'PRODUCT_DELIVERY';
    },
  },
  data: () => ({
    tabOpen: false,
  }),
  methods: {
    toggleTab() {
      if (this.currentlyOpenInfoTab === 'PRODUCT_DELIVERY') {
        this.currentlyOpenInfoTab = undefined;
      } else {
        this.currentlyOpenInfoTab = 'PRODUCT_DELIVERY';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
