<template>
  <div class="notification notification--success">
    <div class="notification__icon mb-5">
      <lottie-animation :animationData="successAnimation" :auto-play="true" />
    </div>

    <div class="notification-text">
      <h2>{{ companyName }} have received your order!</h2>

      <p>Order number: {{ cart.transactionId }}</p>

      <p class="font-weight-light mt-20" data-test="email-confirmation-message">
        A copy of your receipt has been emailed to {{ cart?.confirmationEmailAddress || 'you' }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import successAnimation from '@/assets/lottie/success.json';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useCartStore } from '@/stores/CartStore';

export default {
  name: 'Thankyou',
  computed: {
    ...mapState(useAdvertiserStore, ['companyName']),
    ...mapState(useCartStore, ['cart']),
    successAnimation() {
      return successAnimation;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Notification.scss';
</style>
