<template>
  <div class="form-container">
    <Totals />
    <div v-if="paymentProvider === 'braintree'">
      <BraintreeHostedFields />
    </div>
    <div v-if="paymentProvider === 'adyen'">
      <AdyenDropIn />
    </div>
    <div v-if="!paymentProvider">
      <p>Payment provider failed to load</p>
    </div>
  </div>
</template>

<script>
// Stores
import { mapState, mapActions } from 'pinia';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useMainStore } from '@/stores/MainStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';

// Components
import Totals from '@/components/Totals/index.vue';
import BraintreeHostedFields from '@/components/Payment/Braintree/BraintreeHostedFields/index.vue';
import AdyenDropIn from '@/components/Payment/Adyen/DropIn.vue';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';

import { getRouter } from '@/router';

export default {
  name: 'CheckoutView',
  components: {
    Totals,
    BraintreeHostedFields,
    AdyenDropIn,
  },
  async mounted() {
    // ValidateShippingAddress
    // Check a shipping method is set
    // Otherwise redirect the user back to the previous step

    if (
      !this.validateAddress('shipping', this.address.shipping) ||
      !this.cart.shipping?.selectedShippingMethod
    ) {
      const router = await getRouter();

      this.previousStepInCheckout(router);
    }
  },
  computed: {
    ...mapState(useShippingStore, ['address']),
    ...mapState(useAdvertiserStore, ['paymentProvider']),
    ...mapState(useCartStore, ['cart']),
  },
  methods: {
    ...mapActions(useShippingStore, ['validateAddress']),
    ...mapActions(useMainStore, ['previousStepInCheckout']),
  },
};
</script>
