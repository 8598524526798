import { defineStore } from 'pinia';

type State = {
  advertiserName: string;
  companyName: string;
  logo: string;
  advertiserTerms: string | null;
  advertiserDelivery: string | null;
  advertiserFooter: string | null;
  advertiserSummaryText: string | null;
  paymentProvider: string;
  primaryColor: string;
  secondaryColor: string;
};

const getInitialState = (): State => ({
  advertiserName: '',
  companyName: '',
  logo: '',
  advertiserTerms: null,
  advertiserDelivery: null,
  advertiserFooter: null,
  advertiserSummaryText: null,
  paymentProvider: '',
  primaryColor: '',
  secondaryColor: '',
});

export const useAdvertiserStore = defineStore('advertiserStore', {
  state: (): State => {
    return getInitialState();
  },
  actions: {
    setData(advertiser) {
      const advertiserStore = useAdvertiserStore();

      advertiserStore.advertiserName = advertiser.name;
      advertiserStore.companyName = advertiser.company_name;
      advertiserStore.logo = advertiser.logo;
      advertiserStore.advertiserTerms = advertiser.terms;
      advertiserStore.advertiserDelivery = advertiser.delivery;
      advertiserStore.advertiserFooter = advertiser.footer_text;
      advertiserStore.advertiserSummaryText = advertiser.free_delivery_text;
      advertiserStore.paymentProvider = advertiser.payment_provider;
      advertiserStore.primaryColor = advertiser.checkout_theme?.primary_colour || '#324876';
      advertiserStore.secondaryColor = advertiser.checkout_theme?.secondary_colour || 'blue';
    },
  },
});
