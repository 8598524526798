<template>
  <transition key="sticky" appear mode="out-in">
    <div class="sticky-product-summary" v-show="isShowStickyProductSummary">
      <span
        ><strong>{{ cart?.productQuantity + ' x ' }}</strong
        >{{ product.name }}</span
      >
      <span v-if="cart?.pricing" v-text="getFormattedPrice(getTotalCartPrice)"></span>
    </div>
  </transition>
</template>

<script>
// Stores
import { mapState } from 'pinia';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';

export default {
  name: 'StickyProductSummary',
  computed: {
    ...mapState(useProductStore, ['product', 'isShowStickyProductSummary']),
    ...mapState(useCartStore, ['cart', 'getFormattedPrice', 'getTotalCartPrice']),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
